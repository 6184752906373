// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Homepage from './components/Homepage';
import LayoutDetails from './components/LayoutDetails';
import MapView from './components/MapView';
import Profile from './components/Profile';
import ReactGA from "react-ga4";

ReactGA.initialize("G-4WBE3HZ0GS");
ReactGA.send({ hitType: "pageview", page: "/", title: "Web Main Page" });

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/layout-details/:id" element={<LayoutDetails />} />
          <Route path="/layout-list" element={<h1>Layout List Page</h1>} />
          <Route path="/contact-us" element={<h1>Contact Us Page</h1>} />
          <Route path="/login" element={<h1>Login Page</h1>} />
          <Route path="/map-view" element={<MapView />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
