import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './LayoutDetails.css';
import { FaMapMarkerAlt,FaExclamationTriangle,FaEye, FaHeart, FaPhoneAlt,FaWhatsapp   } from 'react-icons/fa';
import ContactModal from './ContactModal';
import SignInModal from './SignInModal';
import { Share,ChevronLeft,ChevronRight,Share2,MapPin, Download,User, Heart, Phone,Clock , Trees, Shield, Building2, Building, Dumbbell, Fence, Footprints, Activity, SquareAsterisk} from 'lucide-react';

const layouts = [
    {
        mapLink:'https://landlayout.com/leaflet/leaflet.html',
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Layout 1',
        description: 'Short description for layout 1',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    {
        mapLink:'https://landlayout.com/leaflet/leaflet2.html',
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Alur Layout',
        description: 'Vijayapura',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    {
        mapLink:'https://landlayout.com/leaflet/leaflet3.html',
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: 'Siddeshwar Layout',
        description: 'Vijayapura',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    {
        mapLink:'https://landlayout.com/leaflet/leaflet4.html',
        image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        title: '',
        description: 'Subhashree Layout',
        longdescription: 'Nestled amidst the serene hills of Paradise Valley, our estate offers an unparalleled blend of luxury living and natural beauty. Spanning over 200 acres of lush greenery and overlooking panoramic views of the valley, each plot is meticulously designed to harmonize with its surroundings.',
        images: [
            'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        ],
        plots: [
            { id: 1, number: 'Plot 1', area: '100 sqm', priceRange: '₹100,000 - ₹150,000', address: '123 Main Road, Cityville' },
            { id: 2, number: 'Plot 2', area: '120 sqm', priceRange: '₹120,000 - ₹160,000', address: '456 Road, Vijayapura' },
            { id: 3, number: 'Plot 3', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '789 Road, Vijayapura' },
            { id: 4, number: 'Plot 4', area: '110 sqm', priceRange: '₹110,000 - ₹155,000', address: '888 Road Vijayapura' }
        ]
    },
    // Add more layouts as needed
];

const LayoutDetails = () => {
    const { id } = useParams();
    const [layout, setLayout] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

    const handleSignInClose = () => {
        setIsSignInModalOpen(false);
    };

    const handleShowNumberClick = () => {
        setIsSignInModalOpen(true);
    };

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    useEffect(() => {
        const layoutData = layouts.find((l, index) => index.toString() === id);
        setLayout(layoutData);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [id]);

    useEffect(() => {
        // Update the document title with the layout name when layout loads
        if (layout) {
            document.title = layout.title;
        }
    }, [layout]);

    const handleWhatsAppShare = () => {
        if (layout) {
            const layoutName = encodeURIComponent(layout.title);
            const currentURL = encodeURIComponent(window.location.href);
            const shareURL = `https://api.whatsapp.com/send?text=${layoutName}%20-%20${currentURL}`;
            window.open(shareURL, '_blank');
        }
    };
    if (!layout) return <div>Loading...</div>;

    const details = [
        { title: "31", subtitle: "acre mini city" },
        { title: "7.3", subtitle: "acres of open, green spaces" },
        { title: "12", subtitle: "thematic gardens" },
        { title: "5", subtitle: "large embankment parks" },
        { title: "500+", subtitle: "trees & shrubs" },
        { title: "800+", subtitle: "plots" },
        { title: "50", subtitle: "tree flavoured avenues" },
        { title: "15,000 SFT", subtitle: "proposed Clubhouse" },
        { title: "30KM", subtitle: "of road network" },
        { title: "20 & 22M", subtitle: "wide roads" }
    ];
    const condetails = [
        {
          title: "9X12M",
          subtitle: "Configuration",
          description: "1163",
          label: "SBA (Sq.Ft.)"
        },
        {
          title: "9X15M",
          subtitle: "Configuration",
          description: "1454",
          label: "SBA (Sq.Ft.)"
        },
        {
          title: "Large Size",
          subtitle: "Configuration",
          description: "2000+",
          label: "SBA (Sq.Ft.)"
        }
      ];
      const amenities = [
        { icon: <Trees className="w-6 h-6 text-gray-600" />, name: "Swimming" },
        { icon: <Trees className="w-6 h-6 text-gray-600" />, name: "Park" },
        { icon: <Shield className="w-6 h-6 text-gray-600" />, name: "Security" },
        { icon: <Building2 className="w-6 h-6 text-gray-600" />, name: "Temple" },
        { icon: <Building className="w-6 h-6 text-gray-600" />, name: "Children Park" },
        { icon: <Building className="w-6 h-6 text-gray-600" />, name: "Club House" },
        { icon: <Dumbbell className="w-6 h-6 text-gray-600" />, name: "Open Gym" },
        { icon: <Building className="w-6 h-6 text-gray-600" />, name: "Sports Area" },
        { icon: <Fence className="w-6 h-6 text-gray-600" />, name: "Fencing" },
        { icon: <Footprints className="w-6 h-6 text-gray-600" />, name: "Gymnasium" },
        { icon: <Activity className="w-6 h-6 text-gray-600" />, name: "Jogging Track" },
        { icon: <SquareAsterisk className="w-6 h-6 text-gray-600" />, name: "Multipurpose Hall" }
      ];
    return (
        <div className="">
          <div className="layout-details">
            {/* <div className="breadcrumb">
    <Link to="/">Home</Link>
    <span className="breadcrumb-separator">&gt;</span>
    <Link to="/layouts">Layout List</Link>
    <span className="breadcrumb-separator">&gt;</span>
    <span>{layout.title}</span>
</div>

            <div className="layout-name">
                <h1>{layout.title}</h1>
            </div>
             */}
            {/* Responsive iFrame with "Open Interactive Map" Button */}
          {/* Responsive iFrame with "Open Interactive Map" Button */}
<div className="iframe-container">
    <button
        className="map-button"
        onClick={() =>
            window.open(
                layout.mapLink
            )
        }
    >
        Open Interactive Map <FaMapMarkerAlt />
    </button>
    <iframe
        src={
            layout.mapLink
        }
        className="responsive-iframe"
        title="Interactive Layout Map"
    ></iframe>
</div>


{/* <div className="layout-description">
                <div className="description-content">
                    <h2>Description</h2>
                    <p>{layout.longdescription}</p>
                </div>
                <div className="layout-sidebar">
                    <div className="sidebar-section">
                        <h2>Our Checks</h2>
                        <ul>
                            <li>Check 1</li>
                            <li>Check 2</li>
                            <li>Check 3</li>
                        </ul>
                    </div>
                    <div className="sidebar-section">
                        <h2>Other Details</h2>
                        <p>Additional details about the layout. Which helps to get more inside about the layout</p>
                    </div>
                </div>
            </div> */}
   <div className="flex flex-col md:flex-row gap-4 p-6 bg-gray-50 rounded-lg">
  {/* Left Section Box */}
  <div className="flex-1 bg-white p-6 rounded-lg shadow-md border border-gray-200">
    <div className="flex items-center justify-between mb-2">
      <span className="text-blue-600 text-xl font-semibold">$ {layout.price || '00000'}</span>
      <div className="flex gap-2">
        <button className="p-2 hover:bg-gray-100 rounded-full">
          <Heart className="w-6 h-6 stroke-1 text-gray-600" />
        </button>
        <button className="flex items-center gap-1 px-3 py-1 text-gray-600 border rounded-md">
          <Share className="w-4 h-4" />
          <span className="text-sm">Share</span>
        </button>
        <button className="flex items-center gap-1 px-3 py-1 text-gray-600 border rounded-md">
          <Download className="w-4 h-4" />
          <span className="text-sm">Download</span>
        </button>
      </div>
    </div>

    <h1 className="text-xl font-medium text-left mb-4">{layout.title || 'Lorem ipsum'}</h1>

    <div className="flex gap-2">
      <div className="text-green-600 mt-1">
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" fill="currentColor" />
        </svg>
      </div>
      <p className="text-gray-600 text-sm">
        Address: {layout.address || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip'}
      </p>
    </div>
  </div>

  {/* Right Section Box */}
  <div className="md:w-64 bg-white p-6 rounded-lg shadow-md border border-gray-200 flex flex-col gap-3">
    <div className="flex items-center gap-3">
      <img
        src="https://cdn-icons-png.flaticon.com/512/3135/3135768.png"
        alt="Profile"
        className="w-12 h-12 rounded-full object-cover"
      />
      <div className="flex-1 overflow-hidden"> {/* Keeps text section from pushing badge */}
        <div className="font-medium">LandLayout.com</div>
        <div className="text-sm text-gray-600">Lorem ipsum</div>
        <div className="text-sm text-gray-600">+917564******</div>
      </div>
      {/* Wrap badge in its own div for better control */}
      <div className="ml-auto flex-shrink-0">
        <span className="px-3 py-1 bg-green-600 text-white text-sm rounded-md">
          Owner
        </span>
      </div>
    </div>

    <button className="w-full py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50">
      Request Call
    </button>

    <button className="w-full py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 flex items-center justify-center gap-2">
      <FaWhatsapp className="text-green-600" />
      <span className="text-green-600">Whatsapp</span>
    </button>
  </div>
</div>

<div className="flex gap-4 mt-4">
  {/* Left Column */}
  <div className="flex-1 bg-white rounded-lg p-6 shadow-md border border-gray-200">
    <div className="mb-6">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Media</h2>
      <div className="flex gap-2">
        <button className="px-6 py-2 rounded-md bg-green-600 text-white font-semibold">
          Images
        </button>
        <button className="px-6 py-2 rounded-md bg-white text-gray-600 border">
          Video
        </button>
        <button className="px-6 py-2 rounded-md bg-white text-gray-600 border">
          Map
        </button>
      </div>
    </div>

    {/* Main Image */}
    <div className="relative mb-4">
      <img
        src={layout.images[0]}
        alt="Main property view"
        className="w-full h-[400px] object-cover rounded-lg"
      />
    </div>

    {/* Thumbnail Strip */}
    <div className="flex gap-2">
      {layout.images.slice(0, 4).map((img, index) => (
        <img
          key={index}
          src={img}
          alt={`Thumbnail ${index + 1}`}
          className="w-20 h-20 object-cover rounded-lg"
        />
      ))}
    </div>
  </div>

  {/* Right Column */}
  <div className="w-80">
    {/* Activity Card */}
    <div className="bg-white rounded-lg p-6 mb-4 shadow-md border border-gray-200">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Activity On This Property</h2>
      <div className="flex justify-between text-center">
        <div>
          <User className="w-6 h-6 mx-auto mb-2 text-green-600" />
          <div className="font-semibold">45</div>
          <div className="text-sm text-gray-500">Unique Views</div>
        </div>
        <div>
          <Heart className="w-6 h-6 mx-auto mb-2 text-green-600" />
          <div className="font-semibold">5</div>
          <div className="text-sm text-gray-500">Shortlists</div>
        </div>
        <div>
          <Phone className="w-6 h-6 mx-auto mb-2 text-green-600" />
          <div className="font-semibold">4</div>
          <div className="text-sm text-gray-500">Contacted</div>
        </div>
      </div>
    </div>

    {/* Report Card */}
    <div className="bg-white rounded-lg p-6 border border-red-100 shadow-md">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Report this Listing</h2>
      <div className="flex flex-col gap-2">
        <button className="w-full py-2 px-4 border rounded-md text-gray-600 hover:bg-gray-50">
          Sold out
        </button>
        <button className="w-full py-2 px-4 border rounded-md text-gray-600 hover:bg-gray-50">
          Wrong info
        </button>
      </div>
    </div>
  </div>
</div>
<div className="bg-white rounded-lg shadow-md p-6">
  {/* Title */}
  <div className="flex items-center gap-2 mb-4">
    <div className="w-1 h-6 bg-green-600"></div>
    <h2 className="text-xl font-semibold text-gray-800">Overview</h2>
  </div>

  {/* Description */}
  <p className="text-gray-600 mb-8 leading-relaxed">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  </p>

  {/* Property Details Grid */}
  <div className="grid grid-cols-2 md:grid-cols-4 gap-y-6 gap-x-8">
    {/* Land Type */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM4 21V4h16v17H4z" strokeWidth="1"/>
          <path d="M12 7v10M7 12h10" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Land Type</p>
        <p className="text-gray-800 font-semibold">PLOT</p>
      </div>
    </div>

    {/* Project Name */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 16H5V5h14v14z" strokeWidth="1"/>
          <path d="M8 7h8M8 11h8M8 15h5" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Project Name</p>
        <p className="text-gray-800 font-semibold">Lorem ipsum dolor sit amet</p>
      </div>
    </div>

    {/* Fencing */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M4 4v16M8 4v16M12 4v16M16 4v16M20 4v16" strokeWidth="1"/>
          <path d="M4 12h16" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Fencing</p>
        <p className="text-gray-800 font-semibold">Yes</p>
      </div>
    </div>

    {/* Soil type */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M3 19h18M5 15a5 5 0 0 1 5-5c1.8 0 3.4.9 4.3 2.2M12 12a5 5 0 0 1 5-5" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Soil type</p>
        <p className="text-gray-800 font-semibold">Red/ Black</p>
      </div>
    </div>

    {/* Electricity Connection */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Electricity Connection</p>
        <p className="text-gray-800 font-semibold">Right</p>
      </div>
    </div>

    {/* Water Supply */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M12 2v6M12 22c3.3 0 6-2.7 6-6s-6-10-6-10S6 12.7 6 16s2.7 6 6 6z" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Water Supply</p>
        <p className="text-gray-800 font-semibold">YES</p>
      </div>
    </div>

    {/* Approach road */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M4 19h16M4 15h16M4 11h16M4 7h16" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Approach road</p>
        <p className="text-gray-800 font-semibold">40 ft blacktop road</p>
      </div>
    </div>

    {/* Borewell */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <circle cx="12" cy="12" r="3" strokeWidth="1"/>
          <path d="M12 2v3m0 14v3M2 12h3m14 0h3" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Borewell</p>
        <p className="text-gray-800 font-semibold">2</p>
      </div>
    </div>

    {/* Sewage Connection */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M12 2v20M4 5h16M4 19h16" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Sewage Connection</p>
        <p className="text-gray-800 font-semibold">YES</p>
      </div>
    </div>

    {/* Floors allowed for construction */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M3 21h18M3 17h18M3 13h18M3 9h18M3 5h18" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Floors allowed for construction</p>
        <p className="text-gray-800 font-semibold">YES</p>
      </div>
    </div>

    {/* Well */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M3 20h18M6 20v-8h12v8M6 12h12l-2-8H8l-2 8z" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Well</p>
        <p className="text-gray-800 font-semibold">NO</p>
      </div>
    </div>

    {/* Canal */}
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <svg className="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path d="M5 3v18M19 3v18M9 3v18M15 3v18" strokeWidth="1"/>
        </svg>
      </div>
      <div>
        <p className="text-gray-500 text-sm font-semibold">Canal</p>
        <p className="text-gray-800 font-semibold">NO</p>
      </div>
    </div>
  </div>
</div>

<div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
      {/* Location Highlights Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Title with green bar */}
        <div className="flex items-center gap-2 mb-6">
          <div className="w-1 h-6 bg-green-600"></div>
          <h2 className="text-xl font-semibold text-gray-800">Location highlights</h2>
        </div>

        {/* Filter buttons */}
        <div className="flex flex-wrap gap-2 mb-6">
          <button className="px-4 py-2 bg-green-600 text-white rounded-full text-sm font-medium">
            Hospitals
          </button>
          <button className="px-4 py-2 border border-gray-200 text-gray-600 rounded-full text-sm font-medium hover:bg-gray-50">
            Schools
          </button>
          <button className="px-4 py-2 border border-gray-200 text-gray-600 rounded-full text-sm font-medium hover:bg-gray-50">
            Hotels
          </button>
          <button className="px-4 py-2 border border-gray-200 text-gray-600 rounded-full text-sm font-medium hover:bg-gray-50">
            Shopping
          </button>
          <button className="px-4 py-2 border border-gray-200 text-gray-600 rounded-full text-sm font-medium hover:bg-gray-50">
            City Conn...
          </button>
        </div>

        {/* Location list */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-gray-700">Manipal Hospital</span>
            <div className="flex items-center gap-1 text-gray-500">
              <Clock className="w-4 h-4 text-green-600" />
              <span>10 mins</span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-gray-700">Manipal Hospital</span>
            <div className="flex items-center gap-1 text-gray-500">
              <Clock className="w-4 h-4 text-green-600" />
              <span>15 mins</span>
            </div>
          </div>
          <button className="px-4 py-1.5 text-blue-600 text-sm border border-gray-200 rounded-md hover:bg-gray-50">
            View more
          </button>
        </div>
      </div>

      {/* Commute Time Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Title with green bar */}
        <div className="flex items-center gap-2 mb-6">
          <div className="w-1 h-6 bg-green-600"></div>
          <h2 className="text-xl font-semibold text-gray-800">Commute Time</h2>
        </div>

        {/* Input form */}
        <div className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-xs text-gray-500 mb-1">From</label>
              <input
                type="text"
                placeholder="Address"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              />
            </div>
            <div>
              <label className="block text-xs text-gray-500 mb-1">To</label>
              <input
                type="text"
                placeholder="Enter a location to view travel time"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <input type="checkbox" id="peakTraffic" className="rounded border-gray-300" />
              <label htmlFor="peakTraffic" className="text-sm text-gray-600">
                During peak traffic
              </label>
            </div>
            <button className="px-4 py-2 bg-gray-800 text-white rounded-md text-sm font-medium hover:bg-gray-700">
              Show Travel Time
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white py-12">
      {/* Title with green line above */}
      <div className="text-center mb-12">
        <div className="w-12 h-1 bg-green-600 mx-auto mb-4"></div>
        <h2 className="text-3xl font-semibold text-gray-800">Key Features</h2>
      </div>

      {/* Grid layout */}
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-8 gap-y-10">
          {details.map((detail, index) => (
            <div key={index} className="text-left">
              <div className="flex items-baseline gap-1">
                <span className="text-4xl font-semibold text-gray-700">
                  {detail.title}
                </span>
                {detail.suffix && (
                  <span className="text-lg text-gray-500 ml-1">{detail.suffix}</span>
                )}
              </div>
              <p className="text-gray-600 mt-1 text-sm leading-tight">
                {detail.subtitle}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
      {/* Title with green accent */}
      <div className="flex items-center gap-2 mb-6">
        <div className="w-1 h-6 bg-green-600"></div>
        <h2 className="text-xl font-semibold text-gray-800">Amenities</h2>
      </div>

      {/* Amenities Grid */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-y-6">
        {amenities.map((amenity, index) => (
          <div key={index} className="flex items-center gap-3">
            <div className="text-gray-600">
              {amenity.icon}
            </div>
            <span className="text-gray-700">{amenity.name}</span>
          </div>
        ))}
      </div>
    </div>
    
    <div className="bg-white py-12">
      {/* Title with green line above */}
      <div className="text-center mb-12">
        <div className="w-12 h-1 bg-green-600 mx-auto mb-4"></div>
        <h2 className="text-3xl font-semibold text-gray-800">Configuration</h2>
      </div>

     
  
      {/* Grid layout */}
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {condetails.map((detail, index) => (
            <div key={index} className="p-6 border rounded-lg text-center">
              <h3 className="text-lg font-medium text-gray-700 mb-2">
                {detail.subtitle}
              </h3>
              <div className="text-2xl font-semibold text-gray-800 mb-4">
                {detail.title}
              </div>
              <div className="text-gray-600 text-sm mb-1">
                {detail.label}
              </div>
              <div className="text-xl font-semibold text-gray-700">
                {detail.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="bg-gray-50 px-6 py-12">
  <div className="max-w-7xl mx-auto">
    {/* Header */}
    <h2 className="text-3xl font-semibold mb-8">Similar Lands</h2>
    
    {/* Navigation Arrows */}
    <div className="flex justify-end gap-2 mt-4"> {/* Reduced top margin to bring arrows up */}
      <button className="p-2 rounded-full border border-gray-300 hover:bg-gray-50">
        <ChevronLeft className="w-6 h-6 text-gray-600" />
      </button>
      <button className="p-2 rounded-full border border-gray-300 hover:bg-gray-50">
        <ChevronRight className="w-6 h-6 text-gray-600" />
      </button>
    </div>

    {/* Cards Container */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
      {/* Card 1 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Green fields with hills"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      {/* Card 2 */}
      <div className="rounded-lg overflow-hidden shadow-sm mt-4"> {/* Added top margin to lower the card */}
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Terraced rice fields with mountains"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      {/* Card 3 */}
      <div className="rounded-lg overflow-hidden shadow-sm">
        <div className="aspect-[4/3] relative">
          <img 
            src="https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            alt="Tractor in field at sunset"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-600">Lorem ipsum</span>
              <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
            </div>
            <div className="flex gap-2">
              <Heart className="w-6 h-6 text-gray-600" />
              <Share2 className="w-6 h-6 text-gray-600" />
            </div>
          </div>
          <div className="flex items-center gap-1 text-gray-600 mb-3">
            <MapPin className="w-4 h-4" />
            <span>Location</span>
          </div>
          <p className="text-gray-600 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>




        <div className="whatsapp-share">
    <div className="whatsapp-icon" onClick={handleWhatsAppShare}>
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp Icon" />
    </div>
    <span className="share-text" onClick={handleWhatsAppShare}>Share</span>
</div>
</div>
<footer className="bg-[#1B2B3F] text-white py-12">
  <div className="max-w-7xl mx-auto px-4">
    {/* Logo Section */}
    <div className="flex justify-center mb-6">
      <div className="flex items-center gap-2 filter brightness-0 invert">
        <div ><img className="w-25 h-24" src="/LandLayout.com_PNG.png" alt="LandLayout Logo" /></div>
      </div>
    </div>

    {/* Disclaimer Texts */}
    <div className="mb-8">
      <p className="text-gray-300 text-sm leading-relaxed mx-auto">
        Land Layout has endeavoured to ascertain the requirement of RERA registration. However, the advertiser claims that there is no requirement for such registration. Users are cautioned accordingly. Land Layout acts merely as a medium for posting information and content. It is clarified that the data available on the website is not physically verified and hence no representation or warranty is expressly or impliedly given to its accuracy in any form. The User is advised to do thorough research and due diligence before making any decision regarding investment. Nothing contained on the platform shall deem to constitute legal advice, solicitation, invitation etc. in any form whatsoever.
      </p>
    </div>

    {/* Bottom Bar */}
    <div className="border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center">
      {/* Copyright */}
      <div className="text-sm text-gray-400 mb-4 md:mb-0">
        © 2024 LandLayout.com All rights reserved
      </div>

      {/* Social Links */}
      <div className="flex items-center gap-4 mb-4 md:mb-0">
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Instagram</a>
        <span className="text-gray-600 text-sm">•</span>
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Twitter</a>
        <span className="text-gray-600 text-sm">•</span>
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Facebook</a>
      </div>

      {/* Legal Links */}
      <div className="flex items-center gap-4">
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Terms & Condition</a>
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Privacy Policy</a>
      </div>
    </div>
  </div>
</footer>
        </div>
        
    );
};

export default LayoutDetails;
