import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Carousel } from 'react-responsive-carousel';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useSwipeable } from 'react-swipeable';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Homepage.css';
import { ChevronDown,ChevronLeft, ChevronRight, Heart, Share2, MapPin, Play,Star  } from 'lucide-react';

const Homepage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [currentIndexFeatured, setCurrentIndexFeatured] = useState(0);
  const [currentIndexLayoutList, setCurrentIndexLayoutList] = useState(0);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate(); // Initialize useNavigate

  const landTypes = [
    {
      image: "https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Agricultural Land",
      description: "Perfect for farming and cultivation"
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Farmland",
      description: "Ideal for agricultural operations"
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Rural Property",
      description: "Peaceful countryside locations"
    },
    {
      image: "https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Residential Plots",
      description: "Build your dream home"
    }
  ];
  const cards = [
    {
      image: 'https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Layout 1',
      description: 'Short description for layout 1',
    },
    {
      image: 'https://images.unsplash.com/photo-1461175827210-5ceac3e39dd2?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Alur Layout',
      description: 'Vijayapura',
    },
    {
      image: 'https://images.unsplash.com/photo-1597174410299-51f25ccceb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Siddeshwar Layout',
      description: 'Vijayapura',
    },
    {
      image: 'https://images.unsplash.com/photo-1625378314354-0edfa5f28259?q=80&w=2043&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      title: 'Shubhashree Layout',
      description: 'Vijayapura',
    },
    // Add more cards as needed
  ];

  const handlePrev = (section) => {
    if (section === 'featured') {
      const newIndex = currentIndexFeatured - (isMobile ? 1 : 3);
      setCurrentIndexFeatured(newIndex < 0 ? 0 : newIndex);
    } else if (section === 'layoutList') {
      const newIndex = currentIndexLayoutList - (isMobile ? 1 : 3);
      setCurrentIndexLayoutList(newIndex < 0 ? 0 : newIndex);
    }
  };
  
  const handleNext = (section) => {
    if (section === 'featured') {
      const newIndex = currentIndexFeatured + (isMobile ? 1 : 3);
      setCurrentIndexFeatured(newIndex >= cards.length ? cards.length - 1 : newIndex);
    } else if (section === 'layoutList') {
      const newIndex = currentIndexLayoutList + (isMobile ? 1 : 3);
      setCurrentIndexLayoutList(newIndex >= cards.length ? cards.length - 1 : newIndex);
    }
  };
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const swipeHandlersFeatured = useSwipeable({
    onSwipedLeft: () => handleNext('featured'),
    onSwipedRight: () => handlePrev('featured'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  
  const swipeHandlersLayoutList = useSwipeable({
    onSwipedLeft: () => handleNext('layoutList'),
    onSwipedRight: () => handlePrev('layoutList'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  

  const handleCardClick = (index) => {
    navigate(`/layout-details/${index}`);
  };

  const testimonials = [
    {
      rating:4,
      content: "LandLayout.com made finding our dream plot so easy. The detailed aerial images gave us a clear understanding of the land without even visiting the site.",
      name: "Sharanu B.",
      title: "Sharanu B.",
      profileImage:"https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
    },
    {
      rating:5,
      content: "I was able to compare multiple plots quickly and efficiently. The convenience of viewing everything online saved me a lot of time and hassle.",
      name: "Rahul.",
      title: "Sharanu B.",
      profileImage:"https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
    },
    {
      rating:5,
      content: "The clarity of the property on LandLayout.com gave me confidence in my purchase. I highly recommend this platform to anyone looking to buy land.",
      name: "Anjali M.",
      title: "Sharanu B.",
      profileImage:"https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
    },
    {
      rating:5,
      content: "Being able to view high-quality aerial images and clear plot dimensions from home was a game-changer. LandLayout.com made the entire buying process smooth and stress-free.",
      name: "Vikram R.",
      title: "Sharanu B.",
      profileImage:"https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"
    },
  ];

  return (
    <div className="homepage">
       
       <div className="relative h-screen overflow-hidden">
  {/* Background Video */}
  <div className="absolute inset-0">
    <video 
      src="https://cdn.pixabay.com/video/2019/09/24/27197-362173819_large.mp4" 
      autoPlay 
      loop 
      muted 
      className="w-full h-full object-cover"
    ></video>
    {/* Dark Overlay */}
    <div className="absolute inset-0 bg-black/40"></div>
  </div>

  {/* Content */}
  <div className="relative z-10 h-full flex flex-col items-center justify-center px-4">
    {/* Main Title */}
    <h1 className="text-white text-5xl md:text-6xl font-bold mb-2 text-center">
      Lay Out the Land
    </h1>

    {/* Subtitle */}
    <p className="text-white text-lg md:text-2xl mb-12 text-center">
      Connecting Buyers and Sellers
    </p>

    {/* Search Box */}
    <div className="bg-white p-6 rounded-xl shadow-lg w-full max-w-4xl mx-auto">
      <p className="text-center text-gray-800 text-lg md:text-xl mb-6">
        Explore Lands with <span className="font-semibold">Aerial Imagery</span>, 
        <span className="font-semibold"> Multi-Layered Maps</span>, and 
        <span className="font-semibold"> Clear Dimensions</span>
      </p>

      <div className="flex flex-col md:flex-row gap-4 items-center">
        {/* City Dropdown */}
        <div className="flex-1 w-full">
          <p className="text-sm text-gray-600 mb-1">City</p>
          <div className="relative">
            <select 
              className="w-full px-4 py-3 border border-gray-300 rounded-lg appearance-none bg-white pr-10 text-gray-700"
              defaultValue="Bengaluru"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Delhi">Delhi</option>
              {/* Add more options as needed */}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
        </div>

        {/* Land Type Dropdown */}
        <div className="flex-1 w-full">
          <p className="text-sm text-gray-600 mb-1">Land Type</p>
          <div className="relative">
            <select 
              className="w-full px-4 py-3 border border-gray-300 rounded-lg appearance-none bg-white pr-10 text-gray-700"
            >
              <option value="">Select Land Type</option>
              <option value="residential">Residential</option>
              <option value="commercial">Commercial</option>
              <option value="agricultural">Agricultural</option>
              {/* Add more options as needed */}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
        </div>

        {/* Search Button */}
        <button className="w-full md:w-auto px-8 py-3 bg-green-600 text-white rounded-lg font-semibold hover:bg-green-700 transition-colors mt-4 md:mt-0">
          Search
        </button>
      </div>
    </div>
  </div>
</div>



<div className="bg-gray-50 px-6 py-12">
      <div className="max-w-7xl mx-auto">

        {/* Green Bar Above Header */}
        <div className="flex justify-center mb-4">
          <div className="w-16 h-1 bg-green-600"></div>
        </div>

        {/* Header */}
        <h2 className="text-3xl font-semibold text-center mb-8">Featured Layouts</h2>

        {/* Navigation Arrows */}
        <div className="flex justify-end gap-2 mt-4">
          {currentIndexLayoutList > 0 && (
            <button onClick={() => handlePrev('layoutList')} className="p-2 rounded-full border border-gray-300 hover:bg-gray-50">
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
          )}
          {currentIndexLayoutList < cards.length - 3 && (
            <button onClick={() => handleNext('layoutList')} className="p-2 rounded-full border border-gray-300 hover:bg-gray-50">
              <ChevronRight className="w-6 h-6 text-gray-600" />
            </button>
          )}
        </div>

        {/* Cards Container */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          {cards.slice(currentIndexLayoutList, currentIndexLayoutList + 3).map((card, index) => (
            <div
              key={index}
              className={`rounded-lg overflow-hidden shadow-sm cursor-pointer ${index === 1 ? 'mt-4' : ''}`} // Offset the second card
              onClick={() => handleCardClick(currentIndexLayoutList + index)}
            >
              <div className="aspect-[4/3] relative">
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-4">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-semibold text-blue-600">{card.title}</span>
                    <span className="px-2 py-1 bg-green-600 text-white text-sm rounded">Owner</span>
                  </div>
                  <div className="flex gap-2">
                    <Heart className="w-6 h-6 text-gray-600" />
                    <Share2 className="w-6 h-6 text-gray-600" />
                  </div>
                </div>
                <div className="flex items-center gap-1 text-gray-600 mb-3">
                  <MapPin className="w-4 h-4" />
                  <span>Location</span>
                </div>
                <p className="text-gray-600 text-sm">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

{/* <h2 className="card-list-heading">Featured Layouts</h2>
<div className="card-list-container" {...swipeHandlersLayoutList}>
  {currentIndexLayoutList > 0 && !isMobile && <FaArrowLeft onClick={() => handlePrev('layoutList')} className="arrow-icon" />}
  <div className="card-list">
    {cards.slice(currentIndexLayoutList, currentIndexLayoutList + (isMobile ? 1 : 3)).map((card, index) => (
    <div key={index} className="card" onClick={() => handleCardClick(currentIndexLayoutList + index)}>
    <img src={card.image} alt={card.title} className="card-image" />
    <div className="card-content">
      <h3 className="card-title">{card.title}</h3>
      <p className="card-description">{card.description}</p>
    </div>
  </div>
  
    ))}
  </div>
  {currentIndexLayoutList < cards.length - (isMobile ? 1 : 3) && !isMobile && <FaArrowRight onClick={() => handleNext('layoutList')} className="arrow-icon" />}
</div> */}

      
{/* New Section: Why use LandLayout? */}
{/* <section className="why-use-section">
      <h2 className="why-use-heading">Why use LandLayout?</h2>
      <div className="why-use-container">
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/471/471012.png" alt="Explore with Ease" />
          <p className="why-use-text">Explore with Ease: View high-quality aerial images of plots from home.</p>
        </div>
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/16493/16493765.png" alt="Clear Dimensions" />
          <p className="why-use-text">Clear Dimensions: Access accurate plot dimensions instantly.</p>
        </div>
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/1584/1584849.png" alt="Save Time" />
          <p className="why-use-text">Save Time: Avoid unnecessary site visits with online views.</p>
        </div>
        <div className="why-use-box">
          <img src="https://cdn-icons-png.flaticon.com/512/1509/1509666.png" alt="Unique IDs" />
          <p className="why-use-text">Unique IDs: Easily share and track properties with unique IDs.</p>
        </div>
      </div>
    </section> */}

<div className="max-w-7xl mx-auto px-4 py-16">
      {/* Green Bar Above Header */}
      <div className="flex justify-center mb-4">
        <div className="w-24 h-1 bg-green-600"></div>
      </div>

      {/* Header */}
      <div className="text-center mb-16">
  <h2 className="text-4xl font-bold flex items-center justify-center">
    Why
    <img 
      className="mx-1 h-12"  // reduced mx-4 to mx-1
      src="/LandLayout.com_PNG.png" 
      alt="LandLayout Logo"
    />
    ?
  </h2>
</div>


      {/* Two Columns Layout */}
      <div className="grid md:grid-cols-2 gap-8">
        {/* Buyers Column */}
        <div className="bg-green-600 rounded-3xl p-12 text-white md:ml-0">
          <div className="relative">
            {/* Background Text */}
            <div className="absolute -top-4 left-0 text-8xl font-bold opacity-10">
              Buyers
            </div>
            
            {/* Content */}
            <h3 className="text-4xl font-bold mb-12">Buyers?</h3>
            
            <div className="space-y-8">
              <Feature
                title="Explore with Ease"
                description="View high-quality aerial images of land from home."
              />
              <Feature
                title="Multiple Map Layers"
                description="Access accurate dimension and details with ease."
              />
              <Feature
                title="Save Time"
                description="Avoid unnecessary site visits, explore online instead."
              />
              <Feature
                title="Availability Status"
                description="Check if the land is sold, reserved or available"
              />
              <Feature
                title="Unique IDs"
                description="Share, Save and track properties effortlessly with IDs."
              />
            </div>
          </div>
        </div>

        {/* Sellers Column */}
        <div className="bg-gray-50 rounded-3xl p-12 md:mr-0">
          <div className="relative">
            {/* Background Text */}
            <div className="absolute -top-4 left-0 text-8xl font-bold opacity-5">
              Sellers
            </div>
            
            {/* Content */}
            <h3 className="text-4xl font-bold mb-12 text-gray-800">Sellers?</h3>
            
            <div className="space-y-8">
              <Feature
                title="Digital Catalogue"
                description="Showcase complete land specifications with aerial layers."
                light
              />
              <Feature
                title="Embed Our Map"
                description="Integrated LandLayout's interactive map on your own website."
                light
              />
              <Feature
                title="Built-In CRM"
                description="Manage inquiries and track leads seamlessly and easily."
                light
              />
              <Feature
                title="Monitor Interest"
                description="Track user engagement and view counts efficiently."
                light
              />
              <Feature
                title="Maximize Reach"
                description="Share, Save and track properties effortlessly with IDs."
                light
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="max-w-7xl mx-auto px-4 py-16">
  {/* Header */}
  <div className="text-center mb-12">
    {/* Green Bar */}
    <div className="w-12 h-1 bg-green-600 mx-auto mb-4"></div>
    
    {/* Title and Subtitle */}
    <h2 className="text-4xl font-bold mb-2">Land Types</h2>
    <p className="text-xl text-gray-600">
      Discover the property that's right for you
    </p>
  </div>

  {/* Grid Layout */}
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
    {landTypes.map((type, index) => (
      <LandTypeCard
        key={index}
        image={type.image}
        title={type.title}
        description={type.description}
      />
    ))}
  </div>
</div>

<div className="relative w-full h-screen bg-gradient-to-b from-gray-900 to-gray-800 overflow-hidden">
  {/* Background Image */}
  <div className="absolute inset-0">
    <img 
      src="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
      alt="City background" 
      className="w-full h-full object-cover opacity-30"
    />
  </div>

  {/* Content Container */}
  <div className="relative h-full max-w-7xl mx-auto px-4 py-16 flex items-start">
    {/* Text Content */}
    <div className="max-w-lg">
      <div className="w-16 h-1 bg-green-500 mb-4"></div>
      <h2 className="text-5xl font-bold text-white mb-6">
        Featured <span className="text-white">Layouts</span>
      </h2>
      <p className="text-gray-300 mb-8">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
        do eiusmod tempor incididunt ut labore et dolore magna 
        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
      
      <button className="flex items-center gap-2 text-white hover:text-gray-200 transition-colors">
        <Play className="w-6 h-6" />
        <span>Watch the video</span>
      </button>
       {/* Navigation Arrows */}
       <div className="absolute bottom-32 left-4 flex items-center gap-4">
          <button 
            onClick={handlePrev}
            className="w-12 h-12 bg-white/10 hover:bg-white/20 rounded-full flex items-center justify-center transition-colors"
          >
            <ChevronLeft className="w-6 h-6 text-white" />
          </button>
          <button 
            onClick={handlePrev}
            className="w-12 h-12 bg-white/10 hover:bg-white/20 rounded-full flex items-center justify-center transition-colors"
          >
            <ChevronRight className="w-6 h-6 text-white" />
          </button>
        </div>
    </div>

    {/* Video Gallery aligned to the right */}
    <div className="ml-auto w-2/3 flex items-center justify-end">
      <div className="grid grid-cols-3 gap-4">
        {/* Featured Video */}
        <div className="col-span-2 row-span-2">
          <VideoCard 
            image="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            className="h-full"
          />
        </div>
        
        {/* Side Videos */}
        {[...Array(6)].map((_, index) => (
          <VideoCard 
            key={index}
            image="https://plus.unsplash.com/premium_photo-1669227514138-d5e43441b5e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
            className="h-36"
          />
        ))}
      </div>
    </div>
  </div>
</div>

<div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-12">
        <span className="inline-block w-12 h-1 bg-green-600 ml-2" />
          <h2 className="text-4xl font-bold mb-2">
            Testimonials
           
          </h2>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              {...testimonial}
            />
          ))}
        </div>

        {/* Slider Dots */}
        <div className="flex justify-center gap-2 mt-8">
          {[0, 1].map((dot) => (
            <button
              key={dot}
              onClick={() => setCurrentSlide(dot)}
              className={`w-2 h-2 rounded-full transition-colors ${
                currentSlide === dot 
                  ? 'bg-green-600' 
                  : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>

    {/* <div className="features-section">
  <h2 className="features-heading">Key Features of LandLayout</h2>
  <div className="features-grid">
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/3807/3807158.png" alt="Map Marker" className="icon-image" />
      </div>
      <h3>Revolutionize Your Land Search</h3>
      <p>Experience a new way of finding your perfect plot with high-resolution aerial imagery and detailed plot dimensions.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/2163/2163350.png" alt="Home" className="icon-image" />
      </div>
      <h3>View Plots from Home</h3>
      <p>Avoid unnecessary site visits. Get a clear and comprehensive view of the land from the comfort of your home.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/3437/3437338.png" alt="Chart Line" className="icon-image" />
      </div>
      <h3>Easy Comparisons and Sharing</h3>
      <p>Compare multiple plots effortlessly with easily shareable property IDs. Share your favorite properties with friends and family.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/3967/3967544.png" alt="Check Circle" className="icon-image" />
      </div>
      <h3>Transparent and Trustworthy</h3>
      <p>Enjoy a transparent buying process with accurate, up-to-date information and clear visualizations of the land.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/7021/7021218.png" alt="Chart Bar" className="icon-image" />
      </div>
      <h3>For Sellers and Developers</h3>
      <p>Monitor interest with built-in CRM tools and gain valuable insights into user views, likes, and saved plots to optimize your listings.</p>
    </div>
    <div className="feature-item">
      <div className="icon-container">
        <img src="https://cdn-icons-png.flaticon.com/512/610/610128.png" alt="Trending Up" className="icon-image" />
      </div>
      <h3>Market Insights</h3>
      <p>Stay informed with the latest trends in the Indian real estate market, including the growing demand for larger residential spaces and affordable housing initiatives.</p>
    </div>
  </div>
</div> */}
{/* <h2 className="card-list-heading">Featured Layouts</h2>
<div className="card-list-container" {...swipeHandlersFeatured}>
  {currentIndexFeatured > 0 && !isMobile && <FaArrowLeft onClick={() => handlePrev('featured')} className="arrow-icon" />}
  <div className="card-list">
    {cards.slice(currentIndexFeatured, currentIndexFeatured + (isMobile ? 1 : 3)).map((card, index) => (
     <div key={index} className="card" onClick={() => handleCardClick(currentIndexFeatured + index)}>
     <img src={card.image} alt={card.title} className="card-image" />
     <div className="card-content">
       <h3 className="card-title">{card.title}</h3>
       <p className="card-description">{card.description}</p>
     </div>
   </div>
   
    ))}
  </div>
  {currentIndexFeatured < cards.length - (isMobile ? 1 : 3) && !isMobile && <FaArrowRight onClick={() => handleNext('featured')} className="arrow-icon" />}
</div> */}

      {/* <h2 className="contact-heading">Contact Us</h2>
      <div className="contact-container">
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input type="text" id="fullName" name="fullName" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="tel" id="phone" name="phone" required />
          </div>
          <div className="form-group">
            <label htmlFor="info">Info</label>
            <input type="text" id="info" name="info" required />
          </div>
          <button type="submit" className="submit-button">Upload Images</button>
          <button type="submit" className="submit-button">Submit</button>
        </form>
        <div className="contact-info">
          <h3>Contact Information</h3>
          <p>Near Gandi Chowk Vijayapura</p>
          <p>Email: contact@landlayout.com</p>
          <p>Phone: 9933933333</p>
        </div>
      </div> */}
      
  

      <footer className="bg-[#1B2B3F] text-white py-12">
  <div className="max-w-7xl mx-auto px-4">
    {/* Logo Section */}
    <div className="flex justify-center mb-6">
      <div className="flex items-center gap-2 filter brightness-0 invert">
        <div ><img className="w-25 h-24" src="/LandLayout.com_PNG.png" alt="LandLayout Logo" /></div>
      </div>
    </div>

    {/* Disclaimer Texts */}
    <div className="mb-8">
      <p className="text-gray-300 text-sm leading-relaxed mx-auto">
        Land Layout has endeavoured to ascertain the requirement of RERA registration. However, the advertiser claims that there is no requirement for such registration. Users are cautioned accordingly. Land Layout acts merely as a medium for posting information and content. It is clarified that the data available on the website is not physically verified and hence no representation or warranty is expressly or impliedly given to its accuracy in any form. The User is advised to do thorough research and due diligence before making any decision regarding investment. Nothing contained on the platform shall deem to constitute legal advice, solicitation, invitation etc. in any form whatsoever.
      </p>
    </div>

    {/* Bottom Bar */}
    <div className="border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center">
      {/* Copyright */}
      <div className="text-sm text-gray-400 mb-4 md:mb-0">
        © 2024 LandLayout.com All rights reserved
      </div>

      {/* Social Links */}
      <div className="flex items-center gap-4 mb-4 md:mb-0">
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Instagram</a>
        <span className="text-gray-600 text-sm">•</span>
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Twitter</a>
        <span className="text-gray-600 text-sm">•</span>
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Facebook</a>
      </div>

      {/* Legal Links */}
      <div className="flex items-center gap-4">
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Terms & Condition</a>
        <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">Privacy Policy</a>
      </div>
    </div>
  </div>
</footer>


    </div>
  );
};

const Feature = ({ title, description, light = false }) => (
  <div className="flex items-start gap-3">
    <MapPin className={`w-6 h-6 mt-1 ${light ? 'text-green-600' : 'text-white'}`} />
    <div>
      <h4 className={`font-medium text-lg mb-1 ${light ? 'text-gray-800' : 'text-white'}`}>
        {title}
      </h4>
      <p className={light ? 'text-gray-600' : 'text-white/80'}>
        {description}
      </p>
    </div>
  </div>
);
const LandTypeCard = ({ image, title, description }) => (
  <div className="relative overflow-hidden rounded-2xl group">
    <img 
      src={image} 
      alt={title} 
      className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
    />
    <div className="absolute inset-0 bg-black bg-opacity-40 transition-opacity duration-300 group-hover:bg-opacity-50" />
    <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-sm opacity-90">{description}</p>
    </div>
  </div>
);

const VideoCard = ({ image, className = '' }) => (
  <div className={`relative rounded-xl overflow-hidden cursor-pointer ${className}`}>
    <img 
      src={image} 
      alt="Layout video thumbnail" 
      className="w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-black/20 hover:bg-black/30 transition-all duration-300">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
          <Play className="w-6 h-6 text-gray-800 ml-1" />
        </div>
      </div>
    </div>
  </div>
);
const TestimonialCard = ({ rating, content, name, title,profileImage }) => (
  <div className="bg-white rounded-2xl p-8 shadow-lg">
    {/* Rating Stars */}
    <div className="flex gap-1 mb-6">
      {[...Array(5)].map((_, index) => (
        <Star
          key={index}
          className={`w-5 h-5 ${
            index < rating 
              ? 'fill-green-600 text-green-600' 
              : 'fill-gray-200 text-gray-200'
          }`}
        />
      ))}
    </div>

    {/* Testimonial Content */}
    <p className="text-gray-600 mb-8 min-h-[96px]">
      {content}
    </p>

    {/* Author Info */}
    <div className="flex items-center gap-3">
      <img
        src={profileImage}
        alt={name}
        className="w-10 h-10 rounded-full object-cover"
      />
      <div>
        <h4 className="font-semibold text-gray-800">{name}</h4>
        <p className="text-sm text-gray-500">{title}</p>
      </div>
    </div>
  </div>
);

export default Homepage;
